class Module_01_Form {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.form = document.querySelector('.module-01-header.type-home .form_container form');
        cache.field = cache.form.querySelectorAll('select, input:not([type="submit"])');
        cache.submit = cache.form.querySelector('input[type="submit"]');
        cache.nbchildField = cache.form.querySelector('#nb_child');
        cache.childFields = cache.form.querySelectorAll('.child_age');
    };

    let registerEvents = () => {  
      cache.submit.addEventListener("click", submitForm);
      cache.nbchildField.addEventListener("change", childField);
    };

    let childField = function() {
      const nbChild = parseInt(this.value);

      cache.childFields.forEach(child => {
        if(!child.classList.contains('hidden')) {
          child.classList.add('hidden');
        }
      });

      for (let i = 0; i < nbChild; i++) {
        cache.childFields[i].classList.remove('hidden');
      }
    }

    let submitForm = function(e) {
        e.preventDefault();
        let url = 'https://ldv.sax.softvoyage.com/cgi-bin/handler.cgi?code_ag=ldv&alias=ldv&language=fr&searchtype=PA';
        url += "&gateway_dep="+cache.field[0].value;
        url += "&dest_dep="+cache.field[1].value;
        url += "&date_dep="+cache.field[2].value.replace('-','').replace('-','');
        url += "&duration="+cache.field[3].value;
        url += "&nb_adult="+cache.field[4].value;
        url += "&nb_child="+cache.field[5].value;
        if(parseInt(cache.field[5].value) > 0) {
          const nbChild = parseInt(cache.field[5].value);
          for (let i = 0; i < nbChild; i++) {
            url += "&non_adult"+(i+1)+"="+cache.childFields[i].querySelector('select').value;
          }
        }
        url += "&nb_rooms="+cache.field[6].value;
        url += "&star="+cache.field[7].value;
        url += "&all_inclusive=";
        if(cache.field[12].checked) {
            url += "Y";
        } else {
            url += "N";
        }
        url += "&direct_flight=";
        if(cache.field[13].checked) {
            url += "Y";
        } else {
            url += "N";
        }
        url += "&flex=";
        if(cache.field[14].checked) {
            url += "Y";
        } else {
            url += "N";
        }

        window.open(url);
    }


    initFunc();
  };
}
export { Module_01_Form }