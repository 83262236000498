
import { Nav } from './components/nav'
import { Module_01_HeaderSlider } from './modules/module-01-header_slider'
import { Module_01_Form } from './modules/module-01-form'
import { Module_13_Collapse__Module_19_Faq } from './modules/module-13-content_collapse'
import { Module_21_SliderChrono } from './modules/module-21-slider_chrono'
import { Module_22_SliderKey } from './modules/module-22_slider_key'
import { Module_23_Team } from './modules/module-23-team'
import { Module_24_TopDestinations } from './modules/module-24-top'
import { Module_25_dest } from './modules/module-25-dest'
import { Module_26_Promo } from './modules/module-26-promo'
import { Module_28_Office } from './modules/module-28-office'
import { Module_29_Temoignage } from './modules/module-29-temoignage'
import { Module_31_Travel } from './modules/module-31-travel'
import { Module_32_Travel_Dest } from './modules/module-32-travel-dest'
import { Module_33_Infos } from './modules/module-33-infos'
import { Module_34_Realisations } from './modules/module-34-realisations'
import { Module_35_Hub } from './modules/module-35-hubvoyages'

import { Voyages } from './pages/template-voyages'
import { Actualites } from './pages/template-actualites'
import { Blog } from './pages/blog'
import { BlogNav } from './components/blog_nav'

import { PopUp } from './components/popup'

class App {
  constructor() {
    const nav = new Nav;

    if(document.querySelector('.module-01-header.type-slider')) {
      const module_01_HeaderSlider = new Module_01_HeaderSlider;
    }
    if(document.querySelector('.module-01-header.type-home')) {
      const module_01_HeaderForm = new Module_01_Form;
    }
    if(document.querySelector('.module-13-content_collapse, .module-19-faq, .template-voyage .collapse_container')) {
      const module_13_collapse = new Module_13_Collapse__Module_19_Faq;
    }
    if(document.querySelector('.module-21-slider_chrono')) {
      const module_21_SliderChrono = new Module_21_SliderChrono;
    }
    if(document.querySelector('.module-22-slider_key')) {
      const module_22_SliderKey = new Module_22_SliderKey;
    }
    if(document.querySelector('.module-23-team')) {
      const module_23_Team = new Module_23_Team;
    }
    if(document.querySelector('.module-24-top')) {
      const module_24_TopDestinations = new Module_24_TopDestinations;
    }
    if(document.querySelector('.module-25-dest .slider-dest_container.slider-dest')) {
      const module_25_dest = new Module_25_dest;
    }
    if(document.querySelector('.module-26-promo')) {
      const module_26_Promo = new Module_26_Promo ;
    }
    if(document.querySelector('.module-28-office.layout-slider')) {
      const module_28_Office = new Module_28_Office ;
    }
    if(document.querySelector('.module-29-temoignage')) {
      const module_29_Temoignage = new Module_29_Temoignage ;
    }
    if(document.querySelector('.module-31-travel .travel-tab')) {
      const module_31_Travel = new Module_31_Travel ;
    }
    if(document.querySelector('.module-32-travel-dest .travel-dest_tab')) {
      const module_32_Travel_Dest = new Module_32_Travel_Dest ;
    }
    if(document.querySelector('.module-33-infos')) {
      const module_33_Infos = new Module_33_Infos ;
    }
    if(document.querySelector('.module-34-realisations')) {
      const module_34_Realisations = new Module_34_Realisations ;
    }
    if(document.querySelector('.module-35-hubvoyages')) {
      const module_35_Hub = new module_35_Hub ;
    }

    if(document.querySelector('.template-voyage')) {
      const voyages = new Voyages;
    }

    if(document.querySelector('.template-actualites')) {
      const actualites = new Actualites;
    }

    if(document.querySelector('.template-archive-blogue')) {
      const blog = new Blog;
    }

    if(document.querySelector('.blog_nav')) {
      const blogNav = new BlogNav;
    }

    if(document.querySelector('.popup')) {
      const popup = new PopUp;
    }
  }
}

window.onload = function() {
  let app = new App();
};
